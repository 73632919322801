import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import useSharedTabState, { setDefaultTab } from '../hooks/tabState';
import PureIndex from '../components/pure-index/PureIndex';
import PureIndexRedesign from '../components/pure-index/PureIndexRedesign';
import LoadSpinner from '../components/core/loading/LoadSpinner';
import Meta from '../meta/Meta';
import useAdobeTarget from '../hooks/useAdobeTarget';

const Index = ({ data }) => {
  const { setActive } = useSharedTabState();
  const [isLoading, setIsLoading] = React.useState(true);
  const { adobeTesting } = useAdobeTarget('ehi:mc:redesignHomePage');

  React.useEffect(() => {
    if (adobeTesting.adobeHasLoad) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [adobeTesting]);

  React.useEffect(() => {
    setDefaultTab(data, setActive);
  }, []);

  return (
    <>
      <Meta />
      {isLoading && <LoadSpinner />}
      {!isLoading &&
        (adobeTesting.adobeHasLoad && adobeTesting.adobeExprience === 'B' ? (
          <PureIndexRedesign />
        ) : (
          <PureIndex />
        ))}
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allAllianceProductCsv: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({ Account: PropTypes.string, AllianceID: PropTypes.string }),
      ),
    }),
  }).isRequired,
};

export default Index;

export const query = graphql`
  query allianceMapping {
    allAllianceProductCsv {
      nodes {
        Account
        AllianceID
      }
    }
  }
`;
