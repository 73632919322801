import * as React from 'react';
import constants from '../constants/application-constants';

interface InvocaPhone {
  phoneNumber: string;
  isFinal: boolean;
}
export const AppContext = React.createContext<{
  phone: InvocaPhone;
  setPhone: React.Dispatch<React.SetStateAction<InvocaPhone>>;
  partnerAllianceInfo: AlliancePageConfigResponse;
  setPartnerAllianceInfo: React.Dispatch<React.SetStateAction<AlliancePageConfigResponse>>;
} | null>(null);

const AppProvider: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const [phone, setPhone] = React.useState({
    phoneNumber: constants.DEFAULT_PHONE,
    isFinal: false,
  });

  const defaultPartnerAllianceInfo: AlliancePageConfigResponse = {
    data: {
      partnerAllianceInfo: {
        suppressEmailCapturePopup: '',
        allianceData: {
          partnerDisplayName: '',
          serviceHours: '',
          throttle: 'GREEN',
          allianceInfo: {
            allianceLogo: '',
            allianceCompanyUrl: '',
            tcpaDisclaimer: '',
          },
        },
        lightModeHeader: '',
        partnershipType: '',
        segmentType: '',
        landingPageConfigs: [
          {
            pageType: '',
            paramKey: '',
            paramValue: '',
          },
        ],
      },
    },
  };

  const [partnerAllianceInfo, setPartnerAllianceInfo] = React.useState(defaultPartnerAllianceInfo);

  return (
    <AppContext.Provider value={{ phone, setPhone, partnerAllianceInfo, setPartnerAllianceInfo }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;
