import React from 'react';
import CTAbutton from './children/CTAbutton';
import MDCard from './children/MDCard';

/**
 * Savings Module MD Version
 *
 * @returns {JSX.Element}
 */
const SavingsMDVersion = (): JSX.Element => {
  return (
    <section className="max-w-screen-xl mx-auto overflow-hidden px-6 py-6 md:px-6 md:py-8 md:p-10 lg:py-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-12 md:grid-rows-4 md:grid-flow-col">
      <div className="order-1 md:order-2 w-full md:col-span-2 lg:col-span-7 md:row-span-2 md:content-end md:pl-4">
        <h3 className="font-poppin font-bold text-primary-dark text-24 leading-32 lg:text-32 lg:leading-40 text-center md:text-left px-2 mb-6 md:mb-2 lg:mb-4 md:mr-8">
          Add your current plan and Rx drugs to see how much you may save using eHealth
        </h3>
      </div>

      <div className="order-2 md:order-1 w-full md:col-span-1 lg:col-span-5 md:row-span-4 mb-6 md:mb-0 content-center">
        <MDCard />
      </div>

      <div className="order-3 md:order-4 w-full md:col-span-2 lg:col-span-7 flex justify-center md:justify-start md:pl-4 mb-6 md:mb-0 md:mt-4 xl:mt-1">
        <div className="w-full">
          <CTAbutton trackingAction="saving add current plan:see how much you save" />
        </div>
      </div>

      <div className="order-4 md:order-3 w-full md:col-span-2 lg:col-span-7 md:pl-4">
        <p className="font-open-sans font-normal text-black text-14 leading-20 md:text-16 md:leading-24 lg:text-18 lg:leading-28 text-center md:text-left px-2 md:mr-4 lg:mr-24">
          People comparing Medicare plans with eHealth find average savings of{' '}
          <strong>over $400 a year</strong> on prescriptions.<sup>2</sup>
        </p>
      </div>
    </section>
  );
};

export default SavingsMDVersion;
