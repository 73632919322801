import * as React from 'react';
import Zipform from './Zipform';
import envConstants from '../../../globals/constants/environment-constants';

const ModalZipform = (props: { qaSection: string; insuranceType: string }): JSX.Element => {
  const { qaSection, insuranceType } = props;
  const [siteUrl, setSiteUrl] = React.useState('');

  React.useEffect(() => {
    setSiteUrl(envConstants.SITE_URL);
  }, []);

  return (
    <div className="text-center text-gray-dark flex items-center flex-col md:w-full w-auto">
      <h3 className="px-8 md:px-0 text-32 md:text-40 lg:text-48 leading-40 md:leading-56 lg:leading-64 font-bold pb-3 lg:pb-6 text-pink">
        See what&apos;s out there!
      </h3>
      <p className="px-8 md:px-0 text-18 md:text-20 lg:text-24 leading-28 lg:leading-32 font-bold pb-6 lg:pb-8 max-w-full md:max-w-[350px] lg:max-w-[520px] font-poppin">
        Provide your ZIP and we&apos;ll show you Medicare plans in your area. Fast. Easy.
      </p>
      <div className="flex items-center justify-center max-w-full px-8 md:px-0 pb-4 md:py-0 md:max-w-[350px] lg:max-w-[520px]">
        <Zipform qaSection={`${qaSection}ZipModal`} insuranceType={insuranceType} />
      </div>
      <div className="justify-center text-16 leading-24 w-full pb-6 md:p-8 lg:py-10 bg-gray-light rounded-b-md">
        <p className="mt-6 mb-[6px] md:mt-0 font-bold">Other Insurance Options:</p>
        <ul className="flex justify-center items-center flex-wrap">
          <li className="w-full md:hidden md:my-0 px-[6px] py-3">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: ifp"
              href={`${siteUrl}/individual-family-health-insurance`}
            >
              Individual & Family
            </a>
          </li>
          <li className="hidden md:list-item px-[6px] py-3">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: ifp"
              href={`${siteUrl}/individual-family-health-insurance`}
            >
              Individual & Family
            </a>
          </li>
          <li className="px-[6px] py-3 md:border-l border-gray-outline">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: st"
              href={`${siteUrl}/short-term-health-insurance`}
            >
              Short-Term
            </a>
          </li>
          <li className="px-[6px] py-3 border-l border-gray-outline">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: dental"
              href={`${siteUrl}/dental-insurance`}
            >
              Dental
            </a>
          </li>
          <li className="px-[6px] py-3 border-l border-gray-outline">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: vision"
              href={`${siteUrl}/vision-insurance`}
            >
              Vision
            </a>
          </li>
          <li className="w-full md:hidden px-[6px] py-3">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: small business"
              href={`${siteUrl}/small-business-health-insurance`}
            >
              Small Business
            </a>
          </li>
          <li className="hidden md:list-item px-[6px] py-3 lg:border-l border-gray-outline">
            <a
              className="text-green-800 hover:underline font-poppin font-medium"
              data-wa-link="other options: small business"
              href={`${siteUrl}/small-business-health-insurance`}
            >
              Small Business
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ModalZipform;
