import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useSharedTabState from '../../hooks/tabState';

const BannerAdMedicare = ({ handleScroll }: { handleScroll: () => any }) => {
  const { active } = useSharedTabState();
  const currentYear = moment().year();
  const oepStartDate = `${currentYear}/10/01 00:00:00 PDT`;
  const oepEndDate = `${currentYear}/12/7 23:59:59 PST`;
  const [isNonAEP, setIsNonAEP] = useState(true);
  useEffect(() => {
    const isNonAEPTmp = !moment().isBetween(moment(oepStartDate), moment(oepEndDate));
    setIsNonAEP(isNonAEPTmp);
  }, []);
  return (
    <div className={active === 0 ? 'show' : 'hidden'}>
      {isNonAEP ? (
        <div className="w-full">
          <div className="text-white flex flex-row items-center justify-center text-center p-3 text-14 leading-20 font-medium bg-green-800">
            <p className="lg:mr-2 text-14 py-1 md:py-0">
              <span>
                There are many reasons you may be able to enroll in a new Medicare plan now.{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={handleScroll}
                  onKeyDown={handleScroll}
                  role="button"
                  tabIndex={0}
                >
                  Learn more
                </span>
              </span>
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default BannerAdMedicare;
