import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import uuid from 'react-uuid';
import { setCookieStorage, getCookieStorage } from '../../common';
import SavingsMDVersion from './SavingsMDVersion';
import SavingsMAVersion from './SavingsMAVersion';
import envConstants from '../../globals/constants/environment-constants';

/**
 * Savings Component
 *
 * @returns {JSX.Element}
 */
const Savings = (): JSX.Element => {
  const [isContentFadedIn, setIsContentFadedIn] = useState(false);
  const [showMDVersion, setShowMDVersion] = useState(true);

  /**
   * Feature Flag Value Getter
   *
   * Following suit here and calling FF4J directly on each page load (performance?)
   *
   * @param {?string} [featureTag]
   * @returns {boolean}
   */
  const getFF4JResult = (featureTag?: string): boolean => {
    if (!featureTag) {
      return;
    }
    try {
      const ehiVisitorIdInCookie = getCookieStorage('ehiVisitorId');
      let ehiVisitorIdHeader = uuid().replace(/-/g, '').toUpperCase();
      if (ehiVisitorIdInCookie) {
        ehiVisitorIdHeader = ehiVisitorIdInCookie;
      } else {
        setCookieStorage('ehiVisitorId', ehiVisitorIdHeader);
      }
      const ff4jAPIEndpoint = `https://www.${envConstants.SUBDOMAIN}ehealthinsurance.com/ifp-online-api/v1/ff4j/features/${featureTag}`;
      axios
        .get(ff4jAPIEndpoint, {
          headers: {
            VisitorToken: `IFP_API ehi_visitor_id="${ehiVisitorIdHeader}"`,
          },
        })
        .then((response) => {
          const toggleStatus = response.data.enabled;
          setShowMDVersion(toggleStatus);
          setIsContentFadedIn(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log('Fetch FF4J configuration fail.');
    }
  };

  useEffect(() => {
    getFF4JResult('ehiHomeShowSavingsModuleMDVersionToggle');
  }, []);

  return showMDVersion ? (
    <section
      className={classnames(
        'bg-surface-canvas',
        'min-h-[580px] md:min-h-[290px] lg:min-h-[420px]', // setting these because FF swaps content and want prevent flicker
        'flex content-center',
        'transition-opacity duration-500 delay-100 ease-in-out opacity-0',
        isContentFadedIn && '!opacity-100',
      )}
    >
      <SavingsMDVersion />
    </section>
  ) : (
    <section
      className={classnames(
        'bg-surface-canvas',
        'min-h-[580px] md:min-h-[290px] lg:min-h-[420px]', // setting these because FF swaps content and want prevent flicker
        'flex content-center',
        'transition-opacity duration-500 delay-100 ease-in-out opacity-0',
        isContentFadedIn && '!opacity-100',
      )}
    >
      <SavingsMAVersion />
    </section>
  );
};

export default Savings;
