import { useEffect, useState } from 'react';

export default function useAdobeTarget(moboxName: string) {

  const [adobeTesting, setAdobeTesting] = useState({
    adobeHasLoad: false,
    adobeExprience: 'A'
  });

  function getOffer(moboxName: string) {
    try {
      if (!(window as any).adobe) {
        setAdobeTesting({
          ...adobeTesting,
          adobeHasLoad: true
        });
        console.warn(new Error('Adobe Target isn\'t found'));
      } else {
        (window as any).adobe.target.getOffer({
          mbox: moboxName,
          success: (offers: any) => {
            if (offers && offers.length) {
              const offer: any = offers.filter((item: any) => item.action === 'setJson')[0];
              if (offer) {
                const decision: string = (offer.content[0] && offer.content[0].result) || 'A';
                setAdobeTesting({
                  ...adobeTesting,
                  adobeHasLoad: true,
                  adobeExprience: decision
                });
                console.log(`${moboxName}: ${decision}`);
                return;
              }
            }
            setAdobeTesting({
              ...adobeTesting,
              adobeHasLoad: true
            });
            console.warn(`Offer not found. ${moboxName}`);
          },
          error: (status: number, error: any) => {
            setAdobeTesting({
              ...adobeTesting,
              adobeHasLoad: true
            });
            console.log(error);
          },
          timeout: 3000
        });
      }
    } catch (e) {
      setAdobeTesting({
        ...adobeTesting,
        adobeHasLoad: true
      });
      console.log('Get adobe offer error');
    }
  }

  useEffect(() => {
    getOffer(moboxName);
  }, []);

  return { adobeTesting };
}
