import React from 'react';
import { useBetween } from 'use-between';

const currentOepData = () => {
  const [stateAbbr, setStateAbbr] = React.useState(null);
  const [countDownData, setCountDownData] = React.useState(null);

  return { stateAbbr, setStateAbbr, countDownData, setCountDownData };
};

const useSharedOepData = () => useBetween(currentOepData);

export default useSharedOepData;
