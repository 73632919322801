import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import uuid from 'react-uuid';
import useSharedTabState from '../../hooks/tabState';
import envConstants from '../../globals/constants/environment-constants';
import { getBrowserStorage, setCookieStorage, getCookieStorage } from '../../common';

const BannerAd = () => {
  const { active } = useSharedTabState();
  const startingAEPStartDate = '2024/10/01 00:00:00 PDT';
  const startingAEPEndDate = '2024/10/14 23:59:59 PDT';
  const duringAEPStartDate = '2024/10/15 00:00:00 PDT';
  const duringAEPEndDate = '2024/11/24 23:59:59 PST';
  const endingAEPStartDate = '2024/11/25 00:00:00 PST';
  const endingAEPEndDate = '2024/12/7 23:59:59 PST';
  const [countDownStart, setCountDownStart] = useState(0);
  const [countDownEnd, setCountDownEnd] = useState(0);
  const [isShowBanner, setIsShowBanner] = useState(false);
  const [isStartingAEP, setIsStartingAEP] = useState(false);
  const [isDuringAEP, setIsDuringAEP] = useState(false);
  const [isEndingAEP, setIsEndingAEP] = useState(false);
  const [isDesktopHidden, setIsDesktopHidden] = useState(false);
  const [isMobileHidden, setIsMobileHidden] = useState(false);
  const addUrlParam = (url: string, key: string, value: string): string => {
    if (url !== null && url !== undefined) {
      const reg = new RegExp('([?&])' + key + '=.*?(&|$)', 'i'); // eslint-disable-line prefer-template
      const separator = url.indexOf('?') !== -1 ? '&' : '?';
      if (undefined !== value && value !== null) {
        if (url.match(reg)) {
          return url.replace(reg, `$1${key}=${value}$2`);
        }
        return `${url}${separator}${key}=${value}`;
      }
      if (url.match(reg)) {
        return url.replace(reg, '');
      }
    }
    return url;
  };
  const getFF4JResult = (featureTag) => {
    if (!featureTag) {
      return;
    }
    try {
      const ehiVisitorIdInCookie = getCookieStorage('ehiVisitorId');
      let ehiVisitorIdHeader = uuid().replace(/-/g, '').toUpperCase();
      if (ehiVisitorIdInCookie) {
        ehiVisitorIdHeader = ehiVisitorIdInCookie;
      } else {
        setCookieStorage('ehiVisitorId', ehiVisitorIdHeader);
      }
      const ff4jAPIEndpoint = `https://www.${envConstants.SUBDOMAIN}ehealthinsurance.com/ifp-online-api/v1/ff4j/features/${featureTag}`;
      axios
        .get(ff4jAPIEndpoint, {
          headers: {
            VisitorToken: `IFP_API ehi_visitor_id="${ehiVisitorIdHeader}"`,
          },
        })
        .then((response) => {
          const toggleStatus = response.data.enabled;
          if (featureTag === 'ehiHomeAEPBannerDesktopOffToggle') {
            setIsDesktopHidden(toggleStatus);
          } else {
            setIsMobileHidden(toggleStatus);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log('Fetch FF4J configuration fail.');
    }
  };
  const handleRedirectToEHMP = () => {
    const zipValue = getBrowserStorage('zipValue');
    const countyValue = getBrowserStorage('countyValue');
    console.log('zipValue:', zipValue, 'countyValue', countyValue);
    const urlParams = {
      zip: zipValue,
      county: countyValue,
      action: zipValue && countyValue ? 'redirect_with_zip_code' : '',
      lastV1Action: zipValue && countyValue ? 'quoteFromV1' : '',
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
      planYear: 2025,
    };
    let redirectUrl = `${envConstants.EHMP_URL}`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    // eslint-disable-next-line
    if (window._satellite) {
      // eslint-disable-next-line
      const paramList = window._satellite.getVar('Cross Domain Parameters');
      if (paramList) {
        paramList.forEach((param) => {
          redirectUrl = addUrlParam(redirectUrl, param.key, param.value);
        });
      }
    }
    window.location.assign(redirectUrl);
  };
  useEffect(() => {
    const startDate = moment();
    const oepStartDate = moment(duringAEPStartDate);
    const endDate = moment(endingAEPEndDate);
    const datesStartLeft = startDate.isBefore(oepStartDate)
      ? oepStartDate.diff(startDate, 'days')
      : 0;
    const datesEndLeft = startDate.isBefore(endDate) ? endDate.diff(startDate, 'days') : 1;
    setCountDownStart(datesStartLeft + 1);
    setCountDownEnd(datesEndLeft + 1);
    const isStartingAEPInner = moment().isBetween(
      moment(startingAEPStartDate),
      moment(startingAEPEndDate),
    );
    const isDuringAEPInner = moment().isBetween(
      moment(duringAEPStartDate),
      moment(duringAEPEndDate),
    );
    const isEndingAEPInner = moment().isBetween(
      moment(endingAEPStartDate),
      moment(endingAEPEndDate),
    );
    setIsStartingAEP(isStartingAEPInner);
    setIsDuringAEP(isDuringAEPInner);
    setIsEndingAEP(isEndingAEPInner);
    setIsShowBanner(isStartingAEPInner || isDuringAEPInner || isEndingAEPInner || false);
    // Get FF4J result
    getFF4JResult('ehiHomeAEPBannerDesktopOffToggle');
    getFF4JResult('ehiHomeAEPBannerMobileOffToggle');
  }, []);
  return (
    <div id="banner-ad-container" className={active === 0 ? 'show' : 'hidden'}>
      <div
        className={`${isMobileHidden ? 'hidden md:block' : ''} ${
          isDesktopHidden ? 'md:hidden' : ''
        }`}
      >
        {isShowBanner ? (
          <div
            className="w-full cursor-pointer"
            onClick={handleRedirectToEHMP}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRedirectToEHMP();
              }
            }}
            id="aep-top-banner"
            data-wa-link="shop 2025 plans"
            role="button"
            tabIndex={0}
          >
            <div className="text-white flex flex-row items-center justify-center text-center px-3 py-[6px] text-14 leading-20 font-medium bg-[#DC188E]">
              <p className="lg:mr-2 text-14">
                {isDuringAEP ? (
                  <div>
                    <span className="hidden md:inline-block">
                      <b>Medicare Annual Enrollment is here!</b> Compare 2025 Medicare plans today
                    </span>
                    <span className="md:hidden">
                      Annual Enrollment ends <b>12/7</b>
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {isStartingAEP ? (
                  <div>
                    <div className="hidden md:flex items-center gap-1">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="3" y="8" width="18" height="15" fill="white" />
                        <path
                          d="M16.8002 0V2.4H7.20019V0H4.8002V2.4H3.6002C2.2682 2.4 1.2122 3.48 1.2122 4.8L1.2002 21.6C1.2002 22.92 2.2682 24 3.6002 24H20.4002C21.7202 24 22.8002 22.92 22.8002 21.6V4.8C22.8002 3.48 21.7202 2.4 20.4002 2.4H19.2002V0H16.8002ZM20.4002 21.6H3.6002V8.4H20.4002V21.6Z"
                          fill="#4DCB2A"
                        />
                      </svg>
                      <span>
                        <b>
                          {countDownStart} day{countDownStart > 1 && <span>s</span>}
                        </b>{' '}
                        until Medicare Annual Enrollment Period{' '}
                        {countDownStart > 1 && <span>begins!</span>}
                      </span>
                    </div>
                    <div className="flex items-center gap-1 md:hidden">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="3" y="8" width="18" height="15" fill="white" />
                        <path
                          d="M16.8002 0V2.4H7.20019V0H4.8002V2.4H3.6002C2.2682 2.4 1.2122 3.48 1.2122 4.8L1.2002 21.6C1.2002 22.92 2.2682 24 3.6002 24H20.4002C21.7202 24 22.8002 22.92 22.8002 21.6V4.8C22.8002 3.48 21.7202 2.4 20.4002 2.4H19.2002V0H16.8002ZM20.4002 21.6H3.6002V8.4H20.4002V21.6Z"
                          fill="#4DCB2A"
                        />
                      </svg>
                      <span>
                        Annual Enrollment starts <b>10/15</b>
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {isEndingAEP ? (
                  <div className="flex items-center gap-1">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_932_19)">
                        <path
                          d="M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24Z"
                          fill="#4DCB2A"
                        />
                        <path
                          d="M12.4999 21.7846C17.9038 21.7846 22.2846 17.4039 22.2846 12C22.2846 6.59611 17.9038 2.21539 12.4999 2.21539C7.09605 2.21539 2.71533 6.59611 2.71533 12C2.71533 17.4039 7.09605 21.7846 12.4999 21.7846Z"
                          fill="white"
                        />
                        <path
                          d="M12.6587 12.7384C12.1898 12.7384 11.8076 12.3563 11.8076 11.8874V4.68921C11.8076 4.22029 12.1898 3.83813 12.6587 3.83813C13.1276 3.83813 13.5098 4.22029 13.5098 4.68921V11.8892C13.5098 12.3581 13.1276 12.7403 12.6587 12.7403V12.7384Z"
                          fill="#0C6E1E"
                        />
                        <path
                          d="M8.82617 16.2019C8.5991 16.2019 8.38494 16.1132 8.22433 15.9526C8.06371 15.792 7.9751 15.5779 7.9751 15.3508C7.9751 15.1237 8.06371 14.9095 8.22433 14.7489L8.2391 14.736L11.9517 11.3926C12.284 11.0695 12.8176 11.0714 13.1462 11.4C13.4785 11.7323 13.4785 12.2714 13.1462 12.6037L13.1314 12.6166L9.41879 15.96C9.25817 16.1151 9.04956 16.2019 8.82433 16.2019H8.82617Z"
                          fill="#0C6E1E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_932_19">
                          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="hidden md:inline-block">
                      <b>
                        {countDownEnd} day{countDownEnd > 1 && <span>s</span>}
                      </b>{' '}
                      left to enroll{countDownEnd > 1 ? '.' : '!'} Find your 2025 Medicare plan
                      before <b>12/7</b>.
                    </span>
                    <span className="md:hidden">
                      <b>
                        {countDownEnd} day{countDownEnd > 1 && <span>s</span>} left
                      </b>{' '}
                      to enroll!
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default BannerAd;
