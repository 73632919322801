import React, { useLayoutEffect, useState } from 'react';

const useWindowResize = (fireResizEvent = false) => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    updateSize();
    if (fireResizEvent) {
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }
    return () => {
      /**/
    };
  }, []);
  return size;
};

export default useWindowResize;
