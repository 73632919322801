import React, { useEffect } from 'react';
import classnames from 'classnames';
import TpSvg from '../../images/trust/trustpilot.svg';

const styles = {
  image: 'mx-auto w-[152px] md:w-[100px] lg:w-[152px]',
  heading: classnames(
    'text-center text-gray-dark font-bold',
    'text-24 leading-28 my-3',
    'md:text-32 md:leading-38 md:my-6',
    'lg:text-40 lg:leading-52 lg:my-6',
  ),
};

const Trustpilot = () => {
  useEffect(() => {
    setTimeout(() => {
      if (window.Trustpilot && window.Trustpilot.Modules) {
        window.Trustpilot.Modules.WidgetManagement?.initializeOnPageLoad();
      }
    }, 800);
  }, []);
  return (
    <section
      className="px-4 py-8 md:px-6 md:py-10 lg:py-10 text-gray-dark bg-[#E4EFE7]"
      data-wa-depth="Hear from our Medicare customers: depthTracking"
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden">
        <img className={styles.image} alt="rated excellent trustpilot" src={TpSvg} />
        <h2 className={styles.heading}>Hear from our Medicare customers</h2>
        <div className="mx-auto">
          <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="5192fe2c00006400052c7f0a"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="light"
            data-tags="FEAT"
            data-stars="5"
            data-review-languages="en"
          >
            <a
              href="https://www.trustpilot.com/review/ehealthmedicare.com"
              target="_blank"
              rel="noopener noreferrer"
              data-qa="Trustpilot:Link"
              className="hidden"
            >
              Trustpilot
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trustpilot;
