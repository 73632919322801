import React, { useLayoutEffect, useState, useRef } from 'react';
import MainLayout from '../core/layout/MainLayout';
import HeroFlow from '../hero/Hero';
import CarrierLogosColorful from '../carrier-logos/CarrierLogosColorful';
import EhealthLiveAdvise from '../ehealth-live-advise/EhealthLiveAdvise';
import InsuranceTypes from '../insurance-types/InsuranceTypes';
import ThreeSteps from '../three-steps/ThreeSteps';
import Savings from '../savings/Savings';
import SixReasons from '../six-reasons/SixReasons';
import BottomTxt from '../bottom-txt/BottomTxt';
import BannerAd from '../BannerAd/BannerAd';
import BannerAdMedicare from '../BannerAdMedicare/BannerAdMedicare';
import useWindowResize from '../../hooks/useWindowResize';
import constants from '../../globals/constants/application-constants';
import OepBanner from '../oep-banner/OepBanner';
import useSharedTabState from '../../hooks/tabState';
import BottomMedicareTxt from '../bottom-medicare-txt/BottomMedicareTxt';
import AppProvider from '../../globals/context/AppContext';

const PureIndex = () => {
  const { active } = useSharedTabState();
  const [defaultTab, setDefaultTab] = useState(active);
  const windowInnerwidth = useWindowResize(true);
  let isDesktop = windowInnerwidth >= constants.DESKTOP_BREAKPOINT;

  useLayoutEffect(() => {
    isDesktop = windowInnerwidth >= constants.DESKTOP_BREAKPOINT;
  }, [windowInnerwidth]);
  const ref = useRef(null);
  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <AppProvider>
      <MainLayout>
        <BannerAd />
        <OepBanner />
        <HeroFlow defaultTab={defaultTab} />
        <CarrierLogosColorful />
        <BannerAdMedicare handleScroll={handleScroll} />
        <SixReasons />
        {active === 0 && <Savings />}
        {active === 0 ? <EhealthLiveAdvise /> : <ThreeSteps />}
        <InsuranceTypes />
        <BottomTxt />
        <div className="relative">
          <div className="absolute top-[-160px] left-0 w-full h-[1px] z-[-10]" ref={ref} />
          <BottomMedicareTxt />
        </div>
      </MainLayout>
    </AppProvider>
  );
};

export default PureIndex;
