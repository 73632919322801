import React from 'react';
import { getBrowserStorage, redirect } from '../../../common';
import FullscreenModal from '../../fullscreen-modal/FullscreenModal';
import ModalZipform from '../../core/zipform/ModalZipform';

const QA_SECTION = 'SavingsModule';

/**
 * CTA Button Component
 *
 * @param {{ trackingAction: string }} props
 * @returns {JSX.Element}
 */
const CTAButton = (props: { trackingAction: string }): JSX.Element => {
  const { trackingAction } = props;
  const [isModalShowing, setIsModalShowing] = React.useState(false);
  const modalContent = <ModalZipform qaSection={QA_SECTION} insuranceType="medicare" />;

  const openZipModal = () => {
    if (getBrowserStorage('zipValue')) {
      redirect.toEhmp({
        zipValue: getBrowserStorage('zipValue'),
        countyValue: getBrowserStorage('countyValue'),
      });
    } else {
      setIsModalShowing(true);
    }
  };

  return (
    <>
      <button
        type="button"
        className="bg-[#FA6200] text-white rounded-3xl py-2 px-6 font-bold text-16 leading-24 lg:text-20 lg:leading-28 cursor-pointer whitespace-nowrap w-full md:w-auto"
        onClick={() => openZipModal()}
        data-wa-link={trackingAction}
      >
        <span className="inline-block md:hidden">See what you can save</span>
        <span className="hidden md:inline-block">See how much you may save</span>
      </button>

      <FullscreenModal
        isShowing={isModalShowing}
        setIsShowing={setIsModalShowing}
        modalContent={modalContent}
        containerClasses="bg-white"
        qaSection={QA_SECTION}
      />
    </>
  );
};

export default CTAButton;
