import React from 'react';
import MAImage from '../img/ma-image.svg';

const MDCard = (): JSX.Element => {
  return (
    <div className="bg-white shadow rounded-3xl">
      <img src={MAImage} className="w-full h-auto" loading="lazy" alt="" />
    </div>
  );
};

export default MDCard;
