import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useSharedTabState from '../../hooks/tabState';
import { getBrowserStorage, redirect } from '../../common';
import FullscreenModal from '../fullscreen-modal/FullscreenModal';
import ModalZipform from '../core/zipform/ModalZipform';
import MatchmakerIcon from '../../images/icons/matchmaker-icon.png';
import SupportIcon from '../../images/icons/support-icon.png';
import PlansIcon from '../../images/icons/plans-icon.png';
import PersonIcon from '../../images/icons/person-icon.png';
import './carousel.css';

const QA_SECTION = 'SixReasons';

const SixReasons = (): JSX.Element => {
  const { active, setActive } = useSharedTabState();
  const [isModalShowing, setIsModalShowing] = React.useState(false);
  const modalContent = <ModalZipform qaSection={QA_SECTION} insuranceType="medicare" />;
  const styles = {
    color: 'text-[#78A12E]',
    grid: 'hidden md:grid lg:grid grid grid-cols-1 md:grid-cols-2 md:w-[510px] md:gap-7 md:m-auto lg:w-auto lg:gap-6 lg:grid-cols-4',
    tile: {
      container: 'mt-3 mb-10 mx-8 md:m-0 text-center',
      title:
        'font-bold text-18 md:text-20 leading-28 xl:leading-32 md:min-h-[56px] xl:min-h-[64px] xl:text-24 mb-3 md:mb-[6px] xl:mb-3 text-green-darker',
      context: 'text-16 leading-24 xl:text-18 xl:leading-28 font-open-sans text-green-darker',
    },
  };
  const shoppingLinks = [
    {
      label: 'Compare Medicare plans',
      productLine: 'medicare',
    },
    {
      label: 'Compare healthcare plans',
      productLine: 'ifp',
    },
    {
      label: 'Compare healthcare plans',
      productLine: 'smb',
    },
    {
      label: 'Compare hental & vision plans',
      productLine: 'dtvs',
    },
    {
      label: 'Compare short-term plans',
      productLine: 'st',
    },
  ];

  const handleBtnClick = (productLine) => {
    switch (productLine) {
      case 'ifp':
        redirect.toIfp({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'medicare':
        if (getBrowserStorage('zipValue')) {
          redirect.toEhmp({
            zipValue: getBrowserStorage('zipValue'),
            countyValue: getBrowserStorage('countyValue'),
          });
        } else {
          setIsModalShowing(true);
        }
        break;
      case 'smb':
        redirect.toSmb({
          zipValue: getBrowserStorage('zipValue'),
          employeeValue: getBrowserStorage('employeeValue'),
        });
        break;
      case 'st':
        redirect.toST({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'dt':
        redirect.toDT({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'vs':
        redirect.toVS({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      default:
        break;
    }
  };

  return (
    <section
      className="px-6 bg-gray-lightest font-open-sans text-gray-dark pt-6 pb-12 xl:py-20"
      data-wa-depth="Why eHealth? : depthTracking"
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-20 leading-28 mb-6 md:mb-10 xl:mb-10 md:text-32 md:leading-40 xl:text-40 xl:leading-56 font-bold text-center text-green-darker">
            Why eHealth?
          </h2>
        </div>
        <div className={styles.grid}>
          <div className={styles.tile.container}>
            <div className="text-center md:mb-[6px] xl:mb-3 mx-auto md:w-[64px] md:h-[64px] xl:w-[130px] xl:h-[130px]">
              <img src={MatchmakerIcon} alt="matchmaker icon" />
            </div>
            <h3 className={styles.tile.title}>Your Health Insurance Matchmaker</h3>
            <p className={styles.tile.context}>
              Match with plans that cover your doctors and prescriptions, and offer the benefits you
              want.
            </p>
          </div>
          <div className={styles.tile.container}>
            <div className="text-center md:mb-[6px] xl:mb-3 mx-auto md:w-[64px] md:h-[64px] xl:w-[130px] xl:h-[130px]">
              <img src={SupportIcon} alt="support icon" />
            </div>
            <h3 className={styles.tile.title}>Help when you need it</h3>
            <p className={styles.tile.context}>
              Chat or call a licensed eHealth insurance agent to find plans in your area that match
              your needs.
            </p>
          </div>
          <div className={styles.tile.container}>
            <div className="text-center md:mb-[6px] xl:mb-3 mx-auto md:w-[64px] md:h-[64px] xl:w-[130px] xl:h-[130px]">
              <img src={PlansIcon} alt="plans icon" />
            </div>
            <h3 className={styles.tile.title}>Free service — no obligations or fees</h3>
            <p className={styles.tile.context}>
              Get the same plans at the same prices as insurance companies. No enrollment
              obligations ever.
            </p>
          </div>
          <div className={styles.tile.container}>
            <div className="text-center md:mb-[6px] xl:mb-3 mx-auto md:w-[64px] md:h-[64px] xl:w-[130px] xl:h-[130px]">
              <img src={PersonIcon} alt="person icon" />
            </div>
            <h3 className={styles.tile.title}>Independent benefit advisors</h3>
            <p className={styles.tile.context}>
              Your benefit advisors will find plans that match your needs — no matter the carrier.
            </p>
          </div>
        </div>
        <div className="md:hidden xl:hidden">
          <Carousel
            showThumbs={false}
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={50}
          >
            <div className="carousel-slide">
              <div className={styles.tile.container}>
                <div className="text-center mb-3 mx-auto w-[80px] h-[80px]">
                  <img src={MatchmakerIcon} alt="matchmaker icon" />
                </div>
                <h3 className={styles.tile.title}>Your Health Insurance Matchmaker</h3>
                <p className={styles.tile.context}>
                  Match with plans that cover your doctors and prescriptions, and offer the benefits
                  you want.
                </p>
              </div>
            </div>
            <div className="carousel-slide">
              <div className={styles.tile.container}>
                <div className="text-center mb-3 mx-auto w-[80px] h-[80px]">
                  <img src={SupportIcon} alt="support icon" />
                </div>
                <h3 className={styles.tile.title}>Help when you need it</h3>
                <p className={styles.tile.context}>
                  Chat or call a licensed eHealth insurance agent to find plans in your area that
                  match your needs.
                </p>
              </div>
            </div>
            <div className="carousel-slide">
              <div className={styles.tile.container}>
                <div className="text-center mb-3 mx-auto w-[80px] h-[80px]">
                  <img src={PlansIcon} alt="plans icon" />
                </div>
                <h3 className={styles.tile.title}>Free service — no obligations or fees</h3>
                <p className={styles.tile.context}>
                  Get the same plans at the same prices as insurance companies. No enrollment
                  obligations ever.
                </p>
              </div>
            </div>
            <div className="carousel-slide">
              <div className={styles.tile.container}>
                <div className="text-center mb-3 mx-auto w-[80px] h-[80px]">
                  <img src={PersonIcon} alt="person icon" />
                </div>
                <h3 className={styles.tile.title}>Independent benefit advisors</h3>
                <p className={styles.tile.context}>
                  Your benefit advisors will find plans that match your needs — no matter the
                  carrier.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="pt-6 xl:pt-10 text-center">
          {shoppingLinks[active].productLine === 'dtvs' ? (
            <div>
              <button
                type="button"
                onClick={() => handleBtnClick('dt')}
                data-wa-link="dental: find dental plans"
                data-qa={`${QA_SECTION}:Link:Dental`}
                className="bg-[#FA6200] inline-block text-center text-white text-16 md:text-20 leading-24 md:leading-28 font-bold p-3 rounded-3xl w-full md:w-auto md:px-10 md:ml-6 hover:underline"
              >
                Compare dental plans
              </button>
              <button
                type="button"
                onClick={() => handleBtnClick('vs')}
                data-wa-link="vision: find vision plans"
                data-qa={`${QA_SECTION}:Link:Vision`}
                className="bg-[#FA6200] inline-block text-center text-white text-16 md:text-20 leading-24 md:leading-28 font-bold p-3 rounded-3xl w-full md:w-auto md:px-10 ml-0 mt-6 md:mt-0 md:ml-6 hover:underline"
              >
                Compare vision plans
              </button>
            </div>
          ) : (
            <button
              type="button"
              onClick={() => handleBtnClick(shoppingLinks[active].productLine)}
              data-wa-link={`${shoppingLinks[active].productLine}: ${shoppingLinks[
                active
              ].label.toLowerCase()}`}
              data-qa={`${QA_SECTION}:Link:${shoppingLinks[active].productLine}`}
              className="bg-[#FA6200] inline-block text-center text-white text-16 md:text-20 leading-24 md:leading-28 font-bold p-3 rounded-3xl w-full md:w-auto md:px-10 md:ml-6 hover:underline"
            >
              {shoppingLinks[active].label}
            </button>
          )}
        </div>
      </div>
      <FullscreenModal
        isShowing={isModalShowing}
        setIsShowing={setIsModalShowing}
        modalContent={modalContent}
        containerClasses="bg-gray-lightest"
        qaSection={QA_SECTION}
      />
    </section>
  );
};

export default SixReasons;
