import React from 'react';
import './TopBanner.css';

const TopBanner = (): JSX.Element => {
  return (
    <div className="alert">
      This site is used for content validation purposes only. Should you want to learn more about
      eHealth services or sign-up for a health insurance plan, please visit us at{' '}
      <a href="https://www.ehealthinsurance.com" title="eHealth" data-qa="TopBanner:Link:Prod">
        ehealthinsurance.com
      </a>
    </div>
  );
};

export default TopBanner;
