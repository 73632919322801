import React from 'react';
import FirstStepIcon from '../../images/icons/first-step-icon.png';
import SecondStepIcon from '../../images/icons/second-step-icon.png';
import ThirdStepIcon from '../../images/icons/third-step-icon.png';

const ThreeSteps = (): JSX.Element => {
  return (
    <section
      className="px-3 pt-6 pb-12 md:px-6 text-gray-dark bg-white font-open-sans lg:py-20"
      data-wa-depth="Compare plans and enroll in 3 easy steps: depthTracking"
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden">
        <h2 className="flex justify-center mb-6 md:mb-9 lg:mb-10 text-center mx-3 text-20 leading-28 md:text-32 md:leading-40 xl:text-40 xl:leading-56 font-bold text-green-darker">
          Compare plans and enroll in 3 easy steps
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8 lg:gap-20">
          <div className="mb-8 md:mb-0 pb-3 px-8 md:px-[6px] md:pb-3 lg:px-3 lg:pb-1">
            <div className="mx-auto w-[56px] h-[56px] md:w-[64px] md:h-[64px] my-3 md:mb-[6px] lg:mb-3 text-center">
              <img src={FirstStepIcon} alt="first step icon" />
            </div>
            <h3 className="text-18 md:text-20 leading-28 xl:text-24 xl:leading-32 font-bold text-center mb-3 text-green-darker">
              Match with plans that fit your needs
            </h3>
            <p className="w-full max-w-[415px] font-open-sans md:max-w-none text-16 leading-24 xl:text-18 xl:leading-28 mx-auto text-center text-green-darker">
              Enter your zip code, answer a few easy questions about the coverage you need including
              your doctor and your medications.
            </p>
          </div>
          <div className="mb-8 md:mb-0 pb-3 px-8 md:px-[6px] md:pb-3 lg:px-3 lg:pb-1">
            <div className="mx-auto w-[56px] h-[56px] md:w-[64px] md:h-[64px] my-3 md:mb-[6px] lg:mb-3 text-center">
              <img src={SecondStepIcon} alt="second step icon" />
            </div>
            <h3 className="text-18 md:text-20 leading-28 xl:text-24 xl:leading-32 font-bold text-center mb-3 text-green-darker">
              Compare plans side-by-side
            </h3>
            <p className="w-full max-w-[415px] font-open-sans md:max-w-none text-16 leading-24 xl:text-18 xl:leading-28 mx-auto text-center text-green-darker">
              Review the plans in your area that match your needs. Compare benefits, and coverage
              side-by-side.
            </p>
          </div>
          <div className="pb-3 px-8 md:px-[6px] md:pb-3 lg:px-3 lg:pb-1">
            <div className="mx-auto w-[56px] h-[56px] md:w-[64px] md:h-[64px] my-3 md:mb-[6px] lg:mb-3 text-center">
              <img src={ThirdStepIcon} alt="third step icon" />
            </div>
            <h3 className="text-18 md:text-20 leading-28 xl:text-24 xl:leading-32 font-bold text-center mb-3 text-green-darker">
              Enroll online or with an agent
            </h3>
            <p className="w-full max-w-[415px] font-open-sans md:max-w-none text-16 leading-24 xl:text-18 xl:leading-28 mx-auto text-center text-green-darker">
              Enroll online or with an agent over the phone,– no fees or obligations for our
              service.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThreeSteps;
