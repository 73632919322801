import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import envConstants from '../globals/constants/environment-constants';

/* title and meta/description will be customized by individual page */
const Meta = ({ title, description, lang }) => {
  const [siteUrl, setSiteUrl] = React.useState('');
  React.useEffect(() => {
    setSiteUrl(envConstants.SITE_URL);
  }, []);
  const OrganizationSchema = {
    '@context': 'http://schema.org/ ',
    '@type': 'Organization',
    'name': 'eHealth Insurance',
    'logo': 'MISSING',
    'url': 'https://www.ehealthinsurance.com ',
    'foundingDate': '1997',
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': '13620 Ranch Road 620 N, Suite A-250',
      'addressLocality': 'Austin',
      'addressRegion': 'TX',
      'postalCode': '78717',
      'addressCountry': 'United States',
    },

    'contactPoint': {
      '@type': 'ContactPoint',
      'telephone': '833-964-1202',
      'contactType': 'customer service',
      'areaServed': 'US',
      'availableLanguage': ['en'],
    },

    'sameAs': [
      'https://www.youtube.com/user/ehealthinsurance ',
      'https://www.facebook.com/eHealth/ ',
      'https://www.instagram.com/ehealthinsurance/?hl=en ',
      'https://twitter.com/eHealth ',
    ],
  };
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
    >
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="google-site-verification" content="n4-ogeZIsV3QcRkoKrlEfsMBdpYthDla-JfCxFT_cZ0" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="canonical" href={siteUrl} />
      <meta name="description" content={description} />
      <meta
        name="Keywords"
        content="health insurance, health care, medical insurance, health insurance quotes, affordable health insurance, health plans, health insurance coverage"
      />
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ehealth" />
      <meta name="twitter:title" content="eHealth is America’s #1 Private Health Insurance Site" />
      <meta
        name="twitter:description"
        content="Visit eHealth for personalized coverage solutions and a wide selection of plans. Finding health insurance has never been simpler."
      />
      <meta name="twitter:image" content="/social-media-card/summary_card_img.jpg" />
      <meta property="og:title" content="eHealth is America’s #1 Private Health Insurance Site" />
      <meta
        property="og:description"
        content="Visit eHealth for personalized coverage solutions and a wide selection of plans. Finding health insurance has never been simpler."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.ehealthinsurance.com/" />
      <meta property="og:image" content="/social-media-card/summary_card_img.jpg" />
      <meta property="og:site_name" content="ehealth" />
      <meta name="application-name" content="eHealth Insurance" />
      <meta name="msapplication-TileColor" content="#8CC63E" />
      <meta name="msapplication-TileImage" content="/favicons/mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="/favicons/mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="/favicons/mstile-150x150.png" />
      <meta name="msapplication-square310x310logo" content="/favicons/mstile-310x310.png" />
      {(envConstants.SUBDOMAIN === 'qa.' || envConstants.SUBDOMAIN === 'cm.') && (
        <meta name="robots" content="noindex,nofollow" />
      )}

      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="/favicons/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/favicons/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/favicons/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/favicons/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="/favicons/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/favicons/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/favicons/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/favicons/apple-touch-icon-152x152.png"
      />
      <link rel="icon" type="image/png" href="/favicons/favicon-196x196.png" sizes="196x196" />
      <link rel="icon" type="image/png" href="/favicons/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="/favicons/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/favicons/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="/favicons/favicon-128.png" sizes="128x128" />
      <script type="text/javascript">
        {
          '!function (e, v, o) { e[v] || (e[v] = [], e[v].x = e[v].push, e[v].push = function (a, b) { this.x([a, b]) }) }(window, "_waEvents");'
        }
      </script>
      <script type="text/javascript">
        {`;(function(win, doc, style, timeout) {
              var STYLE_ID = "at-body-style";

              function getParent() {
                return doc.getElementsByTagName("head")[0];
              }

              function addStyle(parent, id, def) {
                if (!parent) {
                  return;
                }

                var style = doc.createElement("style");
                style.id = id;
                style.innerHTML = def;
                parent.appendChild(style);
              }

              function removeStyle(parent, id) {
                if (!parent) {
                  return;
                }

                var style = doc.getElementById(id);

                if (!style) {
                  return;
                }

                parent.removeChild(style);
              }

              addStyle(getParent(), STYLE_ID, style);
              setTimeout(function() {
                  removeStyle(getParent(), STYLE_ID);
              }, timeout);
          }(window, document, ".at-hide {opacity: 0 !important}", 3000));`}
      </script>
      <script type="application/ld+json">{JSON.stringify(OrganizationSchema, null, '\t')}</script>
    </Helmet>
  );
};

Meta.defaultProps = {
  description:
    'Get health insurance & Medicare coverage with eHealth, the largest private health insurance market in the USA. Find quotes, compare plans, and get covered.',
  title: 'eHealth | Get Health Insurance Plans & Quotes',
  lang: 'en',
};

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
};

export default Meta;
