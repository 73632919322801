import React from 'react';
import CTAbutton from './children/CTAbutton';
import MACard from './children/MACard';

const SavingsMAVersion = (): JSX.Element => {
  return (
    <section className="max-w-screen-xl mx-auto overflow-hidden px-6 py-6 md:px-6 md:py-8 md:p-10 lg:py-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-12 md:grid-rows-4 md:grid-flow-col">
      <div className="order-1 md:order-2 w-full md:col-span-2 lg:col-span-7 md:row-span-1 md:content-end md:pl-4">
        <h3 className="font-poppin font-bold text-primary-dark text-24 leading-32 lg:text-32 lg:leading-40 text-center md:text-left px-2 mb-6 md:mb-2 lg:mb-4 mx-12 md:mx-0 md:mr-8">
          Switch and you could save
        </h3>
      </div>

      <div className="order-2 md:order-1 w-full md:col-span-1 lg:col-span-5 md:row-span-4 mb-6 md:mb-0 content-center">
        <MACard />
      </div>

      <div className="order-3 md:order-4 w-full md:col-span-2 lg:col-span-7 flex justify-center md:justify-start md:pl-4 mb-6 md:mb-0 md:mt-4 xl:mt-0">
        <div className="w-full">
          <CTAbutton trackingAction="saving switch plan:see how much you save" />
        </div>
      </div>

      <div className="order-4 md:order-3 w-full md:col-span-2 lg:col-span-7 md:row-span-2 md:pl-4">
        <p className="font-open-sans font-normal text-black text-14 leading-20 md:text-16 md:leading-24 lg:text-18 lg:leading-28 text-center md:text-left px-2 md:mr-4 lg:mr-24">
          People who compared their current Medicare Advantage plan to newer 2025 plans using
          eHealth, discovered they could be saving an average of <strong>over $1,100 a year</strong>{' '}
          on medical bills and prescription drugs with a lower cost plan.<sup>2</sup>
        </p>
      </div>
    </section>
  );
};

export default SavingsMAVersion;
