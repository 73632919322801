import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useSharedTabState from '../../hooks/tabState';
import constants from '../../globals/constants/application-constants';

const BottomMedicareTxt = (): JSX.Element => {
  const { active } = useSharedTabState();
  const currentYear = moment().year();
  const oepStartDate = `${currentYear}/10/14 23:59:59 PDT`;
  const oepEndDate = `${currentYear}/12/7 23:59:59 PST`;
  const [isNonAEP, setIsNonAEP] = useState(true);

  useEffect(() => {
    const isNonAEPTmp = !moment().isBetween(moment(oepStartDate), moment(oepEndDate));
    setIsNonAEP(isNonAEPTmp);
  }, []);
  return (
    <div
      id="medicare-bottom-wording"
      className={
        active === 0 && isNonAEP ? 'bg-gray-lightest mt-[-20px]' : 'bg-gray-lightest hidden'
      }
    >
      <div className="w-full lg:max-w-[830px] xl:max-w-[1200px] mx-auto border-t border-solid border-[#cccccc]" />
      <div className="w-full lg:max-w-[830px] xl:max-w-[900px] mx-auto text-center pt-8 pb-12 px-6 lg:px-0">
        <p>
          Certain life events may allow you to sign up or switch plans now such as enrolling for the
          first time, losing your current health coverage, being eligible for both Medicare and
          Medicaid, moving, and other circumstances. Have questions? Call a licensed eHealth sales
          agent to see if you qualify.{' '}
          <span className="invoca-phone-number phone-number">
            {constants.DEFAULT_PHONE} TTY:711
          </span>
        </p>
      </div>
    </div>
  );
};
export default BottomMedicareTxt;
