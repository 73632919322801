import React, { useEffect, ReactElement } from 'react';
import closeIcon from '../../images/icons/close-icon-circle.svg';
import mobileCloseIcon from '../../images/icons/close-icon-mobile.svg';
import useWindowResize from '../../hooks/useWindowResize';
import constants from '../../globals/constants/application-constants';
import envConstants from '../../globals/constants/environment-constants';

const FullscreenModal = ({
  isShowing,
  setIsShowing,
  modalContent,
  containerClasses,
  qaSection,
}: {
  isShowing: boolean;
  setIsShowing: (arg: boolean) => void;
  modalContent: ReactElement;
  containerClasses?: string;
  qaSection: string;
}): JSX.Element => {
  const [hasBanner, setHasBanner] = React.useState(false);
  const [mainOffset, setMainOffset] = React.useState(0);
  const windowInnerwidth = useWindowResize(true);

  const closePopup = () => {
    document.body.style.overflow = '';
    document.removeEventListener('keydown', keyPressFn);
    setIsShowing(false);
  };
  const keyPressFn = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closePopup();
    }
  };
  const handleClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  const handleTab = (e: KeyboardEvent, nextElem: HTMLElement, isLast = false) => {
    if (e.key === 'Tab' && ((!isLast && e.shiftKey) || (isLast && !e.shiftKey))) {
      e.preventDefault();
      nextElem.focus();
    }
  };

  if (isShowing) {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', keyPressFn);
  }

  useEffect(() => {
    let firstElemTabFn: (e: KeyboardEvent) => void;
    let lastElemTabFn: (e: KeyboardEvent) => void;
    let firstTabElem: Element;
    let lastTabElem: Element;
    setHasBanner(envConstants.SUBDOMAIN?.length > 0);
    if (isShowing) {
      const focusableElems = document
        .querySelector('.modal-content')
        .querySelectorAll(
          'input, button, select, textarea, [href], [tabindex]:not([tabindex="-1"])',
        ) as NodeListOf<HTMLElement>;
      if (focusableElems && focusableElems.length) {
        firstElemTabFn = (e) => {
          handleTab(e, focusableElems[focusableElems.length - 1]);
        };
        lastElemTabFn = (e) => {
          handleTab(e, focusableElems[0], true);
        };
        firstTabElem = focusableElems[0];
        lastTabElem = focusableElems[focusableElems.length - 1];
        firstTabElem.addEventListener('keydown', firstElemTabFn, false);
        lastTabElem.addEventListener('keydown', lastElemTabFn, false);
      }
      window._waEvents?.push('pageView', {
        sysEnv: {
          group: 'EHI',
        },
        page: {
          section: 'homepage',
          name: 'Find plans modal',
        },
      });
    } else {
      window._waEvents?.push('closeModal');
    }
    return () => {
      if (firstTabElem && lastTabElem) {
        firstTabElem.removeEventListener('keydown', firstElemTabFn);
        firstTabElem.removeEventListener('keydown', lastElemTabFn);
      }
    };
  }, [isShowing]);

  React.useLayoutEffect(() => {
    const bannerHeight = document
      .getElementsByClassName('alert')[0]
      ?.getBoundingClientRect().height;
    const header = document.getElementsByTagName('ehi-header')[0];
    const offsetMain =
      windowInnerwidth < constants.TABLET_BREAKPOINT
        ? bannerHeight + (header?.getBoundingClientRect().height || 58)
        : bannerHeight;
    setMainOffset(offsetMain || 0);
  }, [windowInnerwidth]);

  return (
    <div>
      {isShowing && (
        // a11y violations: click-events-have-key-events, no-noninteractive-element-interactions
        // eslint-disable-next-line
        <div
          className="modal-wrapper flex justify-center items-center fixed inset-0 z-[10000] overflow-y-scroll bg-gray-dark/40"
          onClick={handleClose}
          role="dialog"
          data-qa={`${qaSection}:ModalWrapper`}
        >
          <div
            className={`relative modal-content w-full min-w-[288px] mx-4 md:mx-0 md:w-[516px] lg:w-[840px] mt-auto mb-auto pt-12 md:pt-6 px-0 md:pt-14 shadow-md rounded-md ${containerClasses}`}
          >
            <div className="close-btn absolute top-[16px] right-[24px]">
              <button
                onClick={closePopup}
                type="button"
                data-qa={`${qaSection}:ModalWrapper:Button`}
              >
                <img src={closeIcon} className="hidden lg:inline" alt="close modal" />
                <img src={mobileCloseIcon} className="inline lg:hidden" alt="close modal" />
              </button>
            </div>
            <div>{modalContent}</div>
          </div>
        </div>
      )}
    </div>
  );
};

FullscreenModal.defaultProps = {
  containerClasses: '',
};

export default FullscreenModal;
