import React from 'react';
import './loading.less';
const LoadSpinner = (): JSX.Element => {
  return (
    <div className="loading-spinner">
      <svg id="loader" width="93" height="93" viewBox="0 0 93 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.1668 4.45801C17.1325 10.0569 4.8335 26.0921 4.8335 45C4.8335 68.5641 23.936 87.6667 47.5002 87.6667C66.408 87.6667 82.4433 75.3676 88.0421 58.3333" stroke="#0c6e1e" stroke-width="8" stroke-linecap="round" />
      </svg>
    </div>
  )
};

export default LoadSpinner;