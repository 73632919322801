import React, { useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import { AppContext } from '../../globals/context/AppContext';

const EhealthLiveAdvise = (): JSX.Element => {
  const sendVideoTracking = (eventName) => {
    if (window._waEvents) {
      window._waEvents.push('trackEvent', {
        page: {
          action: 'track video',
        },
        video: {
          title: 'ehealth Live Advise',
          id: 'JxcVytgcOK8',
        },
        events: [eventName],
      });
    }
  };
  const playerRef = useRef(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [played25Percent, setPlayed25Percent] = useState(false);
  const [played50Percent, setPlayed50Percent] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const handlePlay = () => {
    if (!hasPlayed) {
      sendVideoTracking('video_start');
      setHasPlayed(true);
      setPlayed25Percent(false);
      setPlayed50Percent(false);
    }
  };
  const handleProgress = (state: any) => {
    const { played } = state;
    if (played >= 0.25 && !played25Percent) {
      sendVideoTracking('video_25');
      setPlayed25Percent(true);
    }
    if (played >= 0.5 && !played50Percent) {
      sendVideoTracking('video_50');
      setPlayed50Percent(true);
    }
  };
  const handleEnded = () => {
    sendVideoTracking('video_end');
    setHasPlayed(false);
  };
  const handleClick = () => {
    setShowVideo(true);
  };
  const handleClickToCall = () => {
    if (window._waEvents) {
      window._waEvents.push('trackEvent', {
        page: {
          action: 'live advise: call us',
        },
      });
    }
  };

  const { phone, partnerAllianceInfo } = React.useContext(AppContext);

  return (
    <section
      className="px-3 pt-6 pb-12 md:px-6 text-gray-dark bg-white font-open-sans lg:py-20"
      data-wa-depth="Compare plans and enroll in 3 easy steps: depthTracking"
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden">
        <h2 className="flex justify-center text-center mx-3 text-20 leading-28 md:text-32 md:leading-40 xl:text-40 xl:leading-56 font-bold text-green-darker">
          eHealth Live Advise
          <span>
            <sup>
              <small>TM</small>
            </sup>
          </span>
        </h2>

        <h3 className="flex justify-center mb-6 md:mb-9 lg:mb-10 text-center text-18 leading-28 md:text-20 md:leading-28 font-bold text-green-darker">
          A new way to find your Medicare match!
        </h3>

        <div className="md:flex">
          <div className="grid grid-cols-1 mt-6 md:mt-0 md:w-1/2 bg-gray-lightest shadow rounded-lg text-center p-3 mb-8 md:mb-1 mr-1">
            <div className="relative rounded-lg">
              <div className="w-full video-parent flex content-end aspect-video">
                {showVideo ? (
                  <div className="embed-responsive w-[564px] h-[56vw] md:h-[225px] lg:h-[314px]">
                    <ReactPlayer
                      ref={playerRef}
                      url="https://www.youtube.com/watch?v=JxcVytgcOK8"
                      playing
                      muted
                      width="100%"
                      height="100%"
                      controls
                      onPlay={handlePlay}
                      onProgress={handleProgress}
                      onEnded={handleEnded}
                    />
                  </div>
                ) : (
                  <StaticImage
                    src="../../images/hero/video-hero-ela.png"
                    quality={70}
                    width={564}
                    height={318}
                    loading="lazy"
                    alt="Video"
                    role="presentation"
                    objectFit="cover"
                    className="cursor-pointer video-image w-[564px] h-[56vw] md:h-[225px] lg:h-[314px] rounded-md"
                    onClick={handleClick}
                  />
                )}
              </div>
            </div>

            <p className="text-18 leading-28 lg:text-20 xl:text-24 xl:leading-32 mt-3 font-semibold font-poppin">
              See how it works!
            </p>

            <p className="text-green-800 text-12 leading-16">30 sec video</p>
          </div>

          <div className="grid grid-cols-1 gap-3 md:gap-0 lg:gap-6 md:w-1/2 ml-2 md:ml-6 lg:ml-8">
            <div className="flex pr-3 lg:pr-6">
              <div className="flex content-center">
                <StaticImage
                  src="../../images/icons/screen-icon.png"
                  quality={50}
                  width={48}
                  height={38}
                  loading="lazy"
                  alt="One-way video"
                  className="mx-auto mt-2 mb-3 lg:m-0 w-[32px] h-[26px] lg:w-[48px] lg:h-[38px]"
                />
              </div>

              <div className="ml-4 md:ml-6">
                <h3 className="text-14 lg:text-20 leading-28 font-bold text-green-darker">
                  One-way video &amp; screenshare.
                </h3>

                <p className="w-full font-open-sans text-14 leading-20 lg:text-18 lg:leading-28 mx-auto text-green-darker">
                  See your advisor as they help you compare plans.
                </p>
              </div>
            </div>

            <div className="flex pr-3 lg:pr-6">
              <div className="flex content-center">
                <StaticImage
                  src="../../images/icons/matchmaker-icon.png"
                  quality={50}
                  width={48}
                  height={48}
                  loading="lazy"
                  alt="Guided plan comparison"
                  className="mx-auto mt-2 mb-3 lg:m-0 w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
                />
              </div>

              <div className="ml-4 md:ml-6">
                <h3 className="text-14 lg:text-20 leading-28 font-bold text-green-darker">
                  Guided plan comparison &amp; enrollment.
                </h3>

                <p className="w-full font-open-sans text-14 leading-20 lg:text-18 lg:leading-28 mx-auto text-green-darker">
                  Review plans and see side-by-side comparisons together.
                </p>
              </div>
            </div>

            <div className="flex pr-3 lg:pr-6">
              <div className="flex content-center">
                <StaticImage
                  src="../../images/icons/chat-icon.png"
                  quality={50}
                  width={48}
                  height={48}
                  loading="lazy"
                  alt="All your questions answered"
                  className="mx-auto mt-2 mb-3 lg:m-0 w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
                />
              </div>

              <div className="ml-4 md:ml-6">
                <h3 className="text-14 lg:text-20 leading-28 font-bold text-green-darker">
                  All your questions answered in real time.
                </h3>

                <p className="w-full font-open-sans text-14 leading-20 lg:text-18 lg:leading-28 mx-auto text-green-darker">
                  Your licensed advisor is here to help.
                </p>
              </div>
            </div>

            <div
              className={classnames(
                'md:flex md:pr-3 lg:pr-6 lg:border-t-[1px] lg:border-green-dark pt-4 lg:mt-6 md:mr-6 lg:mr-0',
                partnerAllianceInfo?.data?.partnerAllianceInfo?.allianceData?.throttle === 'RED'
                  ? '!hidden'
                  : null,
              )}
            >
              <div
                className={classnames(
                  'flex lg:hidden flex-col w-full transition-opacity duration-500 justify-center',
                  phone?.isFinal ? 'opacity-100' : 'opacity-0',
                )}
              >
                <p className="text-green-darker text-14 leading-20 font-poppin font-bold text-center mb-2">
                  Ask for it when you call eHealth
                </p>
                <a
                  href={`tel:${phone?.phoneNumber?.replaceAll(/\D/g, '')}`}
                  role="button"
                  className="bg-[#FA6200] inline-block text-center text-white text-16 md:text-20 leading-24 md:leading-28 font-poppin font-bold p-3 rounded-3xl w-full md:w-auto md:px-10 md:ml-6 no-underline hover:underline"
                  onClick={handleClickToCall}
                >
                  <StaticImage
                    src="../../images/icons/call-icon-white.png"
                    quality={100}
                    width={25}
                    height={25}
                    loading="lazy"
                    alt="Call"
                    className="w-[20px] h-[20px] mr-2 mt-[2px]"
                  />{' '}
                  {phone?.phoneNumber}
                </a>
              </div>
              <div
                className={classnames(
                  'hidden lg:flex flex-col w-full transition-opacity duration-500',
                  phone?.isFinal ? 'opacity-100' : 'opacity-0',
                )}
              >
                <p className="text-green-darker text-18 leading-28 md:text-20 md:leading-28 font-poppin font-bold">
                  Ask for eHealth Live Advise
                  <sup>
                    <small>TM</small>
                  </sup>{' '}
                  when you call
                </p>
                <p>
                  <span className="inline-flex">
                    <StaticImage
                      src="../../images/icons/call-icon.png"
                      quality={50}
                      loading="lazy"
                      alt="Call"
                      className="mx-auto mb-3 md:m-0 w-[1em] h-[1em] lg:w-[2em] lg:h-[2em]"
                    />
                  </span>

                  <span
                    className={classnames(
                      'text-40 font-poppin font-bold text-green-800 inline-flex ml-4',
                    )}
                  >
                    {phone?.phoneNumber}
                  </span>

                  <span className="text-40 font-poppin font-extralight text-green-800 inline-flex ml-4">
                    {' TTY 711 '}
                  </span>
                </p>
              </div>
            </div>

            <div
              className={classnames(
                partnerAllianceInfo?.data?.partnerAllianceInfo?.allianceData?.throttle === 'RED'
                  ? 'flex md:h-24 lg:h-32'
                  : 'hidden',
              )}
            >
              {' '}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EhealthLiveAdvise;
