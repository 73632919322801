import React, { useState } from 'react';

import ModalZipform from '../core/zipform/ModalZipform';
import FullscreenModal from '../fullscreen-modal/FullscreenModal';
import { getBrowserStorage, redirect } from '../../common';
import envConstants from '../../globals/constants/environment-constants';
import DoctorIcon from '../../images/icons/doctors-icon.png';
import PrescriptionIcon from '../../images/icons/prescription-icon.png';
import ShoppingCartIcon from '../../images/icons/shopping-cart-icon.png';

const QA_SECTION = 'PlanPrescriber';

const PlanPrescriber = (): JSX.Element => {
  const [IsModalShowing, setIsModalShowing] = useState(false);
  const [ehmpUrl, setEhmpUrl] = React.useState('');

  React.useEffect(() => {
    setEhmpUrl(envConstants.EHMP_URL);
  }, []);

  const openZipModal = () => {
    if (getBrowserStorage('zipValue')) {
      redirect.toEhmp({
        zipValue: getBrowserStorage('zipValue'),
        countyValue: getBrowserStorage('countyValue'),
      });
    } else {
      setIsModalShowing(true);
    }
  };
  const modalContent = <ModalZipform qaSection={QA_SECTION} insuranceType="medicare" />;

  return (
    <section
      className="px-3 md:px-6 pt-6 pb-12 lg:py-20 text-gray-dark"
      data-wa-depth="Get your free personal plan recommendations: depthTracking"
    >
      <div className="max-w-screen-xl mx-auto">
        <h2 className="flex justify-center mb-6 lg:mb-10 text-center mx-3 text-20 leading-28 md:text-24 md:leading-32 xl:text-40 xl:leading-56 font-bold text-green-darker">
          Get your free personal plan recommendations
        </h2>
        <div className="grid md:grid-cols-3 gap-6 md:gap-8 lg:gap-6">
          <div className="grid md:block pb-3 px-8 md:px-3 lg:pb-1">
            <div className="text-center mb-3 mx-auto w-[80px] h-[80px] lg:w-[130px] lg:h-[130px]">
              <img src={DoctorIcon} alt="doctors icon" />
            </div>
            <div className="text-center">
              <h3 className="text-18 md:text-20 leading-28 lg:text-24 lg:leading-32 font-bold mb-3 text-green-darker">
                Check if your doctor is covered
              </h3>
              <p className="text-16 leading-24 lg:text-18 font-open-sans lg:leading-28 text-green-darker">
                Enter your doctors or specialists to compare the plans they accept.
              </p>
            </div>
          </div>
          <div className="grid md:block pb-3 px-8 md:px-3 lg:pb-1">
            <div className="text-center mb-3 mx-auto w-[80px] h-[80px] lg:w-[130px] lg:h-[130px]">
              <img src={PrescriptionIcon} alt="prescriptions icon" />
            </div>
            <div className="text-center">
              <h3 className="text-18 md:text-20 leading-28 lg:text-24 lg:leading-32 font-bold mb-3 text-green-darker">
                Check if your prescriptions are covered
              </h3>
              <p className="text-16 leading-24 lg:text-18 font-open-sans lg:leading-28 text-green-darker">
                Enter your prescriptions to compare their coverage across on multiple plans side by
                side.
              </p>
            </div>
          </div>
          <div className="grid md:block pb-3 px-8 md:px-3 lg:pb-1">
            <div className="text-center mb-3 mx-auto w-[80px] h-[80px] lg:w-[130px] lg:h-[130px]">
              <img src={ShoppingCartIcon} alt="shopping cart" />
            </div>
            <div className="text-center">
              <h3 className="text-18 md:text-20 leading-28 lg:text-24 lg:leading-32 font-bold mb-3 text-green-darker">
                Pick up where you leave off
              </h3>
              <p className="text-16 leading-24 lg:text-18 font-open-sans lg:leading-28 text-green-darker">
                Save your information in one secure location and resume shopping at any time.
              </p>
            </div>
          </div>
        </div>
        <div className="pt-6 lg:pt-10 px-3 md:px-0 text-center">
          <button
            type="button"
            data-wa-link="Plan Prescriber: Find Medicare Plans"
            className="bg-[#FA6200] text-white text-16 md:text-20 leading-24 md:leading-28 font-bold p-3 rounded-3xl w-full md:w-auto md:px-6 hover:underline"
            onClick={openZipModal}
            data-qa={`${QA_SECTION}:Button:FindPlans`}
          >
            Compare Medicare plans
          </button>
          <FullscreenModal
            isShowing={IsModalShowing}
            setIsShowing={setIsModalShowing}
            modalContent={modalContent}
            containerClasses="bg-white"
            qaSection={QA_SECTION}
          />
        </div>
      </div>
    </section>
  );
};

export default PlanPrescriber;
