import React from 'react';
import BBBMobile1x from '../../../images/trust/logo-bbb-mobile.png';
import BBBMobile2x from '../../../images/trust/logo-bbb-mobile@2x.png';
import BBBDesktop3x from '../../../images/trust/logo-bbb-desktop@3x.png';
import './trust.css';

const Trust = ({ qaSection }: { qaSection: string }): JSX.Element => (
  <div
    className="xl:absolute xl:bottom-[59px] xl:left-[53px] flex w-auto items-center justify-center xl:w-[246px] items-center justify-center pb-3 mt-2 md:mt-0 xl:py-[6px] z-10 xl:bg-white/70 xl:py-2 xl:px-4 xl:rounded-[40px] xl:h-[70px]"
    data-qa={`${qaSection}:Trust`}
  >
    <div
      id="DigiCertClickID_onwANETs"
      className="mr-8 xl:mr-3 w-20 md:w-auto filter-outline-white-flowb"
    />
    <div className="xl:hidden">
      <a
        href="https://www.bbb.org/us/ca/santa-clara/profile/insurance-companies/ehealthinsurance-services-1216-359700#bbbseal"
        target="blank"
        rel="noopener"
        title="eHealthInsurance Services, Inc. is a BBB Accredited Insurance Company in Santa Clara, CA"
        data-qa={`${qaSection}:Trust:Link:MobileBBB`}
      >
        <img srcSet={`${BBBMobile1x} 320w, ${BBBMobile2x} 768w`} alt="" className="h-7" />
      </a>
    </div>
    <div className="hidden xl:block">
      <a
        href="https://www.bbb.org/us/ca/santa-clara/profile/insurance-companies/ehealthinsurance-services-1216-359700#bbbseal"
        target="blank"
        rel="noopener"
        title="eHealthInsurance Services, Inc. is a BBB Accredited Insurance Company in Santa Clara, CA"
        data-qa={`${qaSection}:Trust:Link:DesktopBBB`}
      >
        <img srcSet={`${BBBDesktop3x} 992w`} alt="" className="lg:h-10" />
      </a>
    </div>
  </div>
);

export default Trust;
