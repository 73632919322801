import MedicareDesktopImage from '../../../images/hero/background-images/medicare-desktop.png';
import MedicareTabletImage from '../../../images/hero/background-images/medicare-tablet.png';
import IFPDesktopImage from '../../../images/hero/background-images/ifc-desktop.png';
import IFPTabletImage from '../../../images/hero/background-images/ifc-tablet.png';
import SMBDesktopImage from '../../../images/hero/background-images/smb-desktop.png';
import SMBTabletImage from '../../../images/hero/background-images/smb-tablet.png';
import DTDesktopImage from '../../../images/hero/background-images/dt-desktop.png';
import STDesktopImage from '../../../images/hero/background-images/st-desktop.png';
import DTTabletImage from '../../../images/hero/background-images/dt-tablet.png';
import STTabletImage from '../../../images/hero/background-images/st-tablet.png';
import MedicareIcon from '../../../images/icons/medicare-icon.svg';
import IFPIcon from '../../../images/icons/ifp-icon.svg';
import DentalVisionIcon from '../../../images/icons/dt-vsp-icon.svg';
import SMBIcon from '../../../images/icons/small-business-icon.svg';
import STIcon from '../../../images/icons/st-icon.svg';

const tabs = [
  {
    name: 'Medicare Plans',
    icon: MedicareIcon,
    hero: {
      type: 'medicare',
      images: [MedicareTabletImage, MedicareDesktopImage],
      imageContainer: 'md:top-5 md:-right-0 lg:top-12 lg:-bottom-0 lg:-right-80 xl:-right-44',
      imageStyles: 'z-0 lg:w-[575px] lg:h-[620px]',
      bullets: [
        'Enjoy 1-stop shopping for Medicare insurance',
        'Discover popular plans from top carriers',
        'See plans that fit you and your budget',
      ],
      formText: 'Compare Medicare plans and enroll with no fee and no obligation.',
      btnLabel: 'Compare Medicare plans',
    },
  },
  {
    name: 'Individuals & Family',
    icon: IFPIcon,
    hero: {
      type: 'ifp',
      images: [IFPTabletImage, IFPDesktopImage],
      imageContainer: 'md:top-5 md:-right-0 lg:top-12 lg:-bottom-0 lg:-right-80 xl:-right-36',
      imageStyles: 'z-0 md:w-[313px] md:h-[356px] lg:w-[527px] lg:h-[618px]',
      bullets: [
        'Enjoy 1-stop shopping for health insurance',
        'Discover popular plans from top carriers',
        'See plans that fit you and your budget',
      ],
      formText:
        "Compare health insurance plans that match your individual or family's healthcare needs.",
      btnLabel: 'Compare healthcare plans',
    },
  },
  {
    name: 'Employers',
    icon: SMBIcon,
    hero: {
      type: 'smb',
      images: [SMBTabletImage, SMBDesktopImage],
      imageContainer: 'md:top-5 md:-right-0 lg:top-12 lg:-bottom-0 lg:-right-96 xl:-right-56',
      imageStyles: 'z-0 lg:w-[615px] lg:h-[615px]',
      bullets: [
        'Enjoy 1-stop shopping for plans that fit your business and budget',
        'Discover popular plans from top carriers',
        'See plans that feature the benefits your employees want',
      ],
      formText: "Compare health insurance plans that support your employees' health.",
      btnLabel: 'Compare small business plans',
    },
  },
  {
    name: 'Dental & Vision',
    icon: DentalVisionIcon,
    hero: {
      type: 'dental vision',
      images: [DTTabletImage, DTDesktopImage],
      imageContainer: 'md:top-5 md:-right-0 lg:top-12 lg:-bottom-0 lg:-right-20 xl:-right-20',
      imageStyles: 'z-0 lg:w-[528px] lg:h-[575px]',
      bullets: [
        'Enjoy 1-stop shopping for Medicare insurance',
        'Discover popular plans from top carriers',
        'See plans that fit you and your budget',
      ],
      formText: 'Compare customizable dental or vision insurance plans that match your needs.',
      btnLabel: 'Compare dental plans',
    },
  },
  {
    name: 'Short-Term Medical',
    icon: STIcon,
    hero: {
      type: 'st',
      images: [STTabletImage, STDesktopImage],
      imageContainer: 'md:top-5 md:-right-0 lg:top-12 lg:-bottom-0 lg:-right-36 xl:-right-36',
      imageStyles: 'z-0 lg:w-[528px] lg:h-[575px]',
      bullets: [
        'Enjoy 1-stop shopping for Medicare insurance',
        'Discover popular plans from top carriers',
        'See plans that fit you and your budget',
      ],
      formText: 'Compare health insurance plans designed for short-term coverage.',
      btnLabel: 'Compare short-term plans',
    },
  },
];

export default tabs;
