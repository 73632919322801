import envConstants from './globals/constants/environment-constants';
import constants from './globals/constants/application-constants';
import { getCookieStorage } from './common';

/**
 * Fetches alliance data
 *
 * @param allid
 * @returns
 */
export async function fetchAllianceData(allid: string): Promise<AlliancePageConfigResponse> {
  const res = await fetch(`${envConstants.CC_GQL_URL}/graphql`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Method': 'POST',
      'Content-Type': 'application/json',
    },
    //  credentials: 'include',
    body: JSON.stringify({
      operationName: 'partnerAllianceInfo',
      query: `
          query partnerAllianceInfo($allianceId: String!, $allidConfig: String, $allidGroupConfig: String) {
              partnerAllianceInfo(allianceId: $allianceId, allidConfig: $allidConfig, allidGroupConfig: $allidGroupConfig) {
                  allianceId
                  landingPageConfigs{
                    paramValue
                    pageType
                    paramKey
                  }
                  partnershipName
                  partnershipType
                  integrationType
                  lightModeHeader
                  segmentType
                  suppressEmailCapturePopup
                  allianceData {
                      allianceId
                      allianceInfo {
                          allianceLogo
                          allianceLargeLogo
                          allianceName
                          allianceCompanyUrl
                          alliancePhone
                          productLines
                          subCategory
                          tcpaDisclaimer
                          associatedPharmacyChainId
                          associatedPharmacyChainDisplayName
                          partnerId
                          partnerName
                          enableOutOfNetworkPopup
                          hasPreferredPharmacyFilter
                          turnOnPreferredPharmacyFilter
                      }
                      channel
                      partnerDisplayName
                      serviceHours
                      throttle
                  }
              }
          }
              `,
      variables: {
        allianceId: allid,
        allidConfig: allid,
        allidGroupConfig: '',
      },
    }),
  });

  if (!res.ok) {
    throw new Error(`call service for ${allid} fetch not ok`);
  }

  return res.json() as Promise<AlliancePageConfigResponse>;
}

/**
 * Gets partner alliance info
 *
 * @returns
 */
export default async function getPartnerAllianceInfo(): Promise<AlliancePageConfigResponse> {
  const allid = getCookieStorage(constants.ALLIANCE_ID_COOKIE_KEY);
  return fetchAllianceData(allid);
}
