import React from 'react';
import { getBrowserStorage, redirect } from '../../common';
import FullscreenModal from '../fullscreen-modal/FullscreenModal';
import ModalZipform from '../core/zipform/ModalZipform';
import IFPNew from '../../images/insurance-types/desktop/redesign-imgs/IFP-redesign.png';
import MedicareNew from '../../images/insurance-types/desktop/redesign-imgs/Medicare-redesign.png';
import SmallBusinessNew from '../../images/insurance-types/desktop/redesign-imgs/SmallBusiness-redesign.png';
import ShortTermNew from '../../images/insurance-types/desktop/redesign-imgs/ShortTerm-redesign.png';
import DentalNew from '../../images/insurance-types/desktop/redesign-imgs/Dental-redesign.png';
import VisionNew from '../../images/insurance-types/desktop/redesign-imgs/Vision-redesign.png';

const QA_SECTION = 'InsuranceTypes';

const InsuranceTypes = (): JSX.Element => {
  const [isModalShowing, setIsModalShowing] = React.useState(false);
  const modalContent = <ModalZipform qaSection={QA_SECTION} insuranceType="medicare" />;
  const openZipModal = (productLine) => {
    switch (productLine) {
      case 'ifp':
        redirect.toIfp({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'medicare':
        if (getBrowserStorage('zipValue')) {
          redirect.toEhmp({
            zipValue: getBrowserStorage('zipValue'),
            countyValue: getBrowserStorage('countyValue'),
          });
        } else {
          setIsModalShowing(true);
        }
        break;
      case 'smb':
        redirect.toSmb({
          zipValue: getBrowserStorage('zipValue'),
          employeeValue: getBrowserStorage('employeeValue'),
        });
        break;
      case 'st':
        redirect.toST({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'dt':
        redirect.toDT({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'vs':
        redirect.toVS({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      default:
        break;
    }
  };
  const prodInfo = [
    {
      id: 1,
      img: <img className="rounded-lg" src={MedicareNew} alt="medicare people" />,
      title: 'Medicare plans',
      content: [
        'Compare Medicare Advantage, Medicare Supplement, or prescription drug plans.',
        'Find plans that cover your doctors and prescriptions.',
        'Check if switching Medicare plans can increase your coverage while staying in your budget.',
      ],
      btnLabel: 'Compare Medicare plans',
      name: 'medicare',
    },
    {
      id: 2,
      img: <img className="rounded-lg" src={IFPNew} alt="ifp people" />,
      title: 'Individual and family plans',
      content: [
        'Get health insurance coverage for you or your family.',
        'Match with plans that fit your needs and your budget.',
        "Save on doctor's visits, prescription drugs, preventative care and other healthcare services.",
      ],
      btnLabel: 'Compare healthcare plans',
      name: 'ifp',
    },
    {
      id: 3,
      img: <img className="rounded-lg" src={SmallBusinessNew} alt="small business people" />,
      title: 'Small Business plans',
      content: [
        'See if your business qualifies for tax credits to cover some costs of employee premiums.',
        {
          title:
            'Give employees flexibility with Individual Contribution Health Reimbursement Accounts (ICHRA)',
          content: [
            'All employees, even part-time, can participate.',
            'Offer employees more options than traditional plans.',
          ],
        },
      ],
      btnLabel: 'Compare healthcare plans',
      name: 'smb',
    },
    {
      id: 4,
      img: <img className="rounded-lg" src={DentalNew} alt="dental person" />,
      title: 'Dental plans',
      content: [
        'May have relatively low monthly premiums.',
        'Can cover everything from routine cleanings to fillings.',
        'May cover more comprehensive procedures.',
      ],
      btnLabel: 'Compare dental plans',
      name: 'dt',
    },
    {
      id: 5,
      img: <img className="rounded-lg" src={VisionNew} alt="vision person" />,
      title: 'Vision plans',
      content: [
        'Can cover annual eye exams.',
        'May reduce the cost of contact lenses or glasses.',
        'May cover more comprehensive procedures.',
      ],
      btnLabel: 'Compare vision plans',
      name: 'vs',
    },
    {
      id: 6,
      img: <img className="rounded-lg" src={ShortTermNew} alt="short term people" />,
      title: 'Short-term plans',
      content: [
        'Designed to provide temporary health coverage.',
        'Offers limited coverage at affordable prices.',
        'Easily enroll online and start coverage in a few days.',
      ],
      btnLabel: 'Compare short-term plans',
      name: 'st',
    },
  ];
  return (
    <section
      className="px-3 md:px-6 pt-6 pb-12 lg:py-20 text-gray-dark bg-gray-light font-open-sans"
      data-wa-depth="Select the coverage you need for any stage of life: depthTracking"
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden">
        <h2 className="flex justify-center mb-6 lg:mb-12 text-center mx-3 text-20 leading-28 md:text-32 md:leading-40 xl:text-40 xl:leading-56 font-bold text-green-darker">
          Select the coverage you need for any stage of life
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6 p-1">
          {prodInfo.map((productLine) => {
            return (
              <div
                className="mb-6 md:mb-0 p-6 pb-12 md:pb-[148px] bg-white relative shadow rounded-lg"
                key={productLine.id}
              >
                <div className="grid md:block mx-auto mb-6">{productLine.img}</div>
                <h3 className="text-20 text-center leading-28 xl:text-24 xl:leading-32 font-bold mb-3 text-green-darker">
                  {productLine.title}
                </h3>
                <ul className="w-full max-w-[415px] md:max-w-none lg:leading-27 mx-auto list-disc list-outside text-16 leading-24 md:text-18 md:leading-28 font-open-sans font-normal">
                  {productLine.content.map((con) => {
                    if (typeof con === 'string') {
                      return (
                        <li
                          className="ml-6 text-16 leading-24 xl:text-18 xl:leading-28 text-green-darker"
                          key={con}
                        >
                          {con}
                        </li>
                      );
                    }
                    return (
                      <li
                        className="ml-6 text-16 leading-24 xl:text-18 xl:leading-28 text-green-darker"
                        key={con.title}
                      >
                        {con.title}
                        <ul className="list-disc list-outside">
                          {con.content.map((conInner) => {
                            return (
                              <li
                                className="ml-4 text-16 leading-24 xl:text-18 xl:leading-28 text-green-darker"
                                key={conInner}
                              >
                                {conInner}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
                <button
                  type="button"
                  className="bg-[#FA6200] w-full md:w-auto md:absolute md:left-1/2 whitespace-nowrap md:-translate-x-2/4 md:bottom-10 rounded-3xl inline-block mt-6 mx-auto text-white py-2 px-6 font-bold text-16 leading-24 lg:text-20 lg:leading-28 cursor-pointer"
                  onClick={() => openZipModal(productLine.name)}
                  onKeyPress={() => openZipModal(productLine.name)}
                  data-wa-link={
                    productLine.name === 'ifp' || productLine.name === 'smb'
                      ? `${productLine.name} ${productLine.btnLabel.toLowerCase()}`
                      : productLine.btnLabel.toLowerCase()
                  }
                >
                  {productLine.btnLabel}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <FullscreenModal
        isShowing={isModalShowing}
        setIsShowing={setIsModalShowing}
        modalContent={modalContent}
        containerClasses="bg-white"
        qaSection={QA_SECTION}
      />
    </section>
  );
};

export default InsuranceTypes;
