import * as React from 'react';
import './Dialog.less';
import closeIcon from '../../../images/icons/close-white.svg';

function Dialog({
  content,
  isOpen,
  onClick,
  parentRef,
  qaSection, // use PascalCase
}: {
  content: string;
  isOpen: boolean;
  onClick: () => void;
  parentRef: React.MutableRefObject<HTMLElement>;
  qaSection: string;
}): JSX.Element {
  const classList = `dialog absolute p-3 w-72 rounded text-14 text-white bg-gray-dark items-start
    ${isOpen ? 'flex' : 'hidden'}`;

  const closeBtn: React.MutableRefObject<HTMLButtonElement> = React.useRef(null);
  const [isDialogFocused, setIsDialogFocused] = React.useState(false);
  const dynamicID = Math.random().toString().replace('.', '');

  React.useEffect(() => {
    if (isOpen) {
      setIsDialogFocused(true);
      closeBtn.current.focus();
    } else if (isDialogFocused) {
      setIsDialogFocused(false);
      parentRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className={classList} role="dialog" aria-labelledby={`dialog-label-${dynamicID}`}>
      <p id={`dialog-label-${dynamicID}`} className="text-left">
        {content}
      </p>
      <button
        data-qa={`${qaSection}:Dialog:Button`}
        type="button"
        ref={closeBtn}
        className="close-btn p-2 -mt-3 -mr-2 w-8 h-8"
        onClick={onClick}
      >
        <img src={closeIcon} alt="Close" />
      </button>
    </div>
  );
}

export default Dialog;
